.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

a {
  color: inherit;
  text-decoration: none; /* no underline */
}

.sectionFrame {
  background-color: rgb(209, 227, 200);
  min-height: 100vh;
  min-width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  z-index: 2;
  scroll-snap-align: center;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.progress {
  position: fixed;
  left: 0;
  right: 0;
  height: 5px;
  background: black;
  bottom: 1%;
  z-index: 1000;
}

h2 {
  margin: 0;
  color: black;
  left: calc(50% + 130px);
  font-size: 56px;
  font-weight: 700;
  letter-spacing: -3px;
  line-height: 1.2;
  position: absolute;
  z-index: 0;
}

.container {
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
  position: relative;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  scroll-snap-type: y mandatory;
}

@media (min-aspect-ratio: 16/9) {
  .backgroundVideo{
    overflow: hidden;
    position:fixed;
    
    z-index: -1;
    height: auto;
    align-items: center;
    width: 100%;
  }
}
@media (max-aspect-ratio: 16/9) {
  .backgroundVideo{
    overflow: hidden;
    position:fixed;
    margin-right: auto;
    margin-left: auto;
    z-index: -1;
    height: 100%;
    align-items: center;
    width: auto;
  }
}

@media screen and (max-width: 450px) {
  .backgroundVideo{
    overflow: hidden;
    position:fixed;
    right: -110vw;
    margin-right: auto;
    margin-left: auto;
    z-index: -1;
    height: 100%;
    align-items: center;
    width: auto;
  }
}

.gradientOrange {
  --bg-size: 400%;
  --color-one: hsl(15 90% 55%);
  --color-two: hsl(40 95% 55%);
  background: linear-gradient(
                90deg,
                var(--color-one),
                var(--color-two),
                var(--color-one)
              ) 0 0 / var(--bg-size) 100%;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  animation: move-bg 12s infinite linear;
}

.gradientOrangeStatic {
  --bg-size: 400%;
  --color-one: hsl(15 90% 55%);
  --color-two: hsl(40 95% 55%);
  background: linear-gradient(
                90deg,
                var(--color-one),
                var(--color-two),
                var(--color-one)
              ) 0 0 / var(--bg-size) 100%;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}


.gradientGreen {
  --bg-size: 400%;
  --color-one: hsl(119, 47%, 49%);
  --color-two: hsl(100, 66%, 61%);
  background: linear-gradient(
                90deg,
                var(--color-one),
                var(--color-two),
                var(--color-one)
              ) 0 0 / var(--bg-size) 100%;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  animation: move-bg 12s infinite linear;
}

.gradientGreenStatic {
  --bg-size: 400%;
  --color-one: hsl(119, 47%, 49%);
  --color-two: hsl(100, 66%, 61%);
  background: linear-gradient(
                90deg,
                var(--color-one),
                var(--color-two),
                var(--color-one)
              ) 0 0 / var(--bg-size) 100%;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.gradientBlue {
  --bg-size: 400%;
  --color-one: hsl(194, 74%, 60%);
  --color-two: hsl(187, 58%, 55%);
  background: linear-gradient(
                90deg,
                var(--color-one),
                var(--color-two),
                var(--color-one)
              ) 0 0 / var(--bg-size) 100%;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  animation: move-bg 12s infinite linear;
}

.gradientBlueStatic {
  --bg-size: 400%;
  --color-one: hsl(194, 74%, 60%);
  --color-two: hsl(187, 58%, 55%);
  background: linear-gradient(
                90deg,
                var(--color-one),
                var(--color-two),
                var(--color-one)
              ) 0 0 / var(--bg-size) 100%;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.sectionHeader{
  padding: 50px 50px 50px 50px;
  position: absolute;
  top: 0;
  left: 0;
}

.sectionHeader h1{
  font-size: 35px;
  margin: 0px 0px 0px 0px;
}

/* @keyframes move-bg {
  to {
    background-position: var(--bg-size) 0;
  }
} */


/*!
 * Hover.css (http://ianlunn.github.io/Hover/)
 * Version: 2.3.2
 * Author: Ian Lunn @IanLunn
 * Author URL: http://ianlunn.co.uk/
 * Github: https://github.com/IanLunn/Hover
 * Hover.css Copyright Ian Lunn 2017. Generated with Sass.
 */

 .hvr-shrink {
  margin-top: -2px;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}
.hvr-shrink:hover, .hvr-shrink:focus, .hvr-shrink:active {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}
