.BioSection{
    background-color: white;
    opacity: 100%;
    display: flexbox;
}

.Card{
    flex-shrink: 0;
    width: 100%;
    height: min-content; /* 320px */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: visible;
    position: relative;
    padding: 0px 0px 0px 0px;
    align-content: center;
    flex-wrap: nowrap;
    border-radius: 0px 0px 0px 0px;
}

.CardContent{
    flex-shrink: 0;
    width: 1000px;
    height: min-content; /* 320px */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: visible;
    z-index: 1;
    position: relative;
    align-content: center;
    gap: 60px;
    border-radius: 0px 0px 0px 0px;
}

.pfp {
    flex-shrink: 0;
    width: 32%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    z-index: 1;
    position: relative;
    padding: 0px 0px 0px 0px;
    align-content: center;
    flex-wrap: nowrap;
    gap: 10;
    border-radius: 69px;

}


.BioSection {
    flex-shrink: 0;
    width: 50%;
    height: min-content; /* 112px */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    padding: 0px 0px 0px 0px;
    align-content: flex-start;
    flex-wrap: nowrap;
    gap: 20;
    border-radius: 0px 0px 0px 0px;
}

.HeaderRow{
    display: flex;
    align-items: flex-start;
    padding: 100px;
}
h1 {
    flex-shrink: 0;
    width: auto;
    height: auto;
    white-space: pre;
    overflow: visible;
    position: relative;
    font-weight: 600;
    font-style: normal;
    font-family: "Inter", sans-serif;
    color: black;
    font-size: 24px;
    letter-spacing: -0.7px;
    line-height: 1.5;
    text-align: left;
}
p {
    flex-shrink: 0;
    width: 390px;
    height: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
    overflow: visible;
    position: relative;
    font-weight: 500;
    font-style: normal;
    font-family: "Inter", sans-serif;
    color:  #888888;
    font-size: 20px;
    letter-spacing: -0.5px;
    line-height: 1.4;
    text-align: left;
}

@media (max-width: 763px){
    .CardContent{
        flex-shrink: 0;
        width: 100%;
        height: min-content; /* 320px */
        display: flex;
        flex-direction: column;
        padding: 0px 20px 0px 20px;
        justify-content: center;
        align-items: center;
        overflow: visible;
        z-index: 1;
        position: relative;
        align-content: center;
        gap: 60px;
        border-radius: 0px 0px 0px 0px;
    }

    .pfp{
        height: 150px;
        width: auto;
    }
    h1{
        width: 300px;
        margin: 0px auto 0px auto;
    }
    p{
        font-size: 15px;
        width: 300px;
        word-wrap: normal;
    }
}

@media (max-width: 480px){
    .CardContent{
        flex-shrink: 0;
        width: 100%;
        height: min-content; /* 320px */
        display: flex;
        flex-direction: column;
        padding: 0px 20px 0px 20px;
        justify-content: center;
        align-items: center;
        overflow: visible;
        z-index: 1;
        position: relative;
        align-content: center;
        gap: 60px;
        border-radius: 0px 0px 0px 0px;
    }

    .pfp{
        height: 150px;
        width: auto;
    }
    h1{
        width: 300px;
        margin: 0px auto 0px auto;
    }
    p{
        font-size: 15px;
        width: 300px;
        word-wrap: normal;
    }
}

@media (max-width: 350px){
    .CardContent{
        flex-shrink: 0;
        width: 100%;
        height: min-content; /* 320px */
        display: flex;
        flex-direction: column;
        padding: 0px 20px 0px 20px;
        justify-content: center;
        align-items: center;
        overflow: visible;
        z-index: 1;
        position: relative;
        align-content: center;
        gap: 60px;
        border-radius: 0px 0px 0px 0px;
    }

    .pfp{
        height: 150px;
        width: auto;
    }
    h1{
        width: 300px;
        margin: 0px auto 0px auto;
    }
    p{
        font-size: 15px;
        width: 300px;
        word-wrap: normal;
    }
}

